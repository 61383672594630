/* ==========================================================================
   Base Styles & Reset
   ========================================================================== */
* {
  margin: 0;
  box-sizing: border-box;
  font-size: 17px;
  font-family: 'JetBrains Mono', sans-serif;
  letter-spacing: -1.0px;
  /* Commented font families moved here for reference
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto Mono', sans-serif; letter-spacing: -0.5px;
  font-family: 'EB Garamond', sans-serif; letter-spacing: 1px; font-size: 1.0rem;
  font-family: 'Sansita', sans-serif;
  font-family: 'Cormorant Garamond', sans-serif; font-size: 1.05rem; letter-spacing: 1px;
  font-family: 'IBM Plex Sans', sans-serif;
  */
}

body {
  background-color: #000;
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1) 10%); */
  color: #fff;
  padding: 0.05rem;
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ==========================================================================
   Layout & Container
   ========================================================================== */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.0rem;
}

.light-divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid rgba(250, 250, 250, 0.1);
    margin: 2.0em 0;
    padding: 0;
}

/* ==========================================================================
   Typography
   ========================================================================== */
h1, h2 {
  font-weight: 700;
}

.hero-title {
  font-size: 4.0rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p, .info-text, .cta-text {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.text-highlight {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0 1.5rem;
  color: rgba(255, 255, 255, 0.95);
}

/* ==========================================================================
   Navigation & Header
   ========================================================================== */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  font-size: 1.25rem;
}

.nav-menu {
  position: relative;
}

.menu-links {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-button {
  color: black;
  text-decoration: none;
  padding: 0.25rem;
  max-width: 40%;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.2s ease;
  margin: 1rem 0;
}

/* ==========================================================================
   Buttons & Interactive Elements
   ========================================================================== */
button, .apply-button-large {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;

  /* white button */
  color: #000;
  background-color: #fff;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.1) 100%);
    
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
  width: 100%;
  margin: 1rem 0;
}

.cta-button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  width: 29%;
  text-align: left;
  font-size: 1.25rem;
  color: #000;
  background-color: #fff;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.1) 100%);
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.2s ease;
  margin: 1rem 0;
}

.cta-button:hover {
  opacity: 0.8;
}

/* Responsive styling for mobile */
@media (max-width: 56rem) {
  .cta-button {
    width: 100%;
    text-align: center;
  }
}

.read-faq-button {
  display: inline-block;
  border: 1px solid white;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border-radius: 4px;
}

/* ==========================================================================
   Footer
   ========================================================================== */
.footer {
  background-color: #000;
  color: #fff;
  padding-bottom: 2rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.footer-section {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.0);
  border: 2px solid rgba(250, 250, 250, 0.1);
  border-radius: 4px;
  padding: 1rem;
  margin: 0.5rem;
}

.footer-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.footer-text {
  opacity: 0.9;
  font-size: 0.9rem;
}

.footer-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-input {
  padding: 0.75rem;
  font-size: 1rem;
  margin: 0rem 0rem 1rem;
  background-color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
}

.footer-button {
  padding: 0.75rem;
  font-size: 1rem;
  margin: 0rem 0rem 1rem;
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

@media (min-width: 56rem) {
  .content-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .content-section {
    /* width: 50%; */
  }
  
  .footer-form {
    flex-direction: row;
  }
  
  .footer-input {
    flex: 2;
  }
  
  .footer-button {
    flex: 1;
  }
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (min-width: 56rem) {
  .container {
    max-width: 56rem;
    padding: 1.75rem 1.25rem;
  }

  .hero-title {
    font-size: 4.5rem;
  }

  p, .info-text, .cta-text {
    font-size: 1.125rem;
  }

  button {
    width: auto;
  }

  .bottom-bar {
    flex-direction: row;
    text-align: left;
  }

  .menu-icon {
    display: none;
  }

}

@media (max-width: 56rem) {
  .menu-icon {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 2.0rem;
    cursor: pointer;
    padding: 0rem;
    margin: 0rem;
  }

  .menu-links {
    display: none;
    top: 100%;
    right: 0;
    background-color: black;
    padding: 1rem;
    flex-direction: column;
    gap: 2rem;
    border: 2px solid rgba(250, 250, 250, 0.1);
    border-radius: 4px;
  }

  .menu-open {
    display: flex;
  }

  .nav-link {
    font-size: 2.5rem;
    width: 150px;
  }
}

/* ==========================================================================
   List Styles
   ========================================================================== */
.crossed-out {
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.4);
}

.list-item {
  font-size: 1.5rem;
  padding: 0.25rem 0rem;
}

.list-grid {
    margin-bottom: 1.0rem;
}

.list-grid-text {
  margin-bottom: 0rem;
  line-height: 1.5;
  font-size: 1.0rem;
}

.bold {
  font-weight: 700;
}

.centered {
  text-align: center;
}


/* ==========================================================================
   Generic Styles as Classes
   ========================================================================== */
.white-text {
  color: white;
}

.black-text {
  color: black;
}

/* ==========================================================================
   Content Sections
   ========================================================================== */

 .content-section {
    margin-bottom: 0.5rem;
    padding: 0.5rem 0rem;
    /* background-color: #white; */
  }
  
.expand-section {
  margin: 1rem 0.5rem;
  padding: 1.0rem 0.5rem;
  border: 2px solid rgba(250, 250, 250, 0.1);
  border-radius: 4px;
  /* background-color: #f9f9f9; */
}

.expand-section:hover {
    background-color: rgba(250, 250, 250, 0.075);
    border: 2px solid rgba(250, 250, 250, 0.2);
  }

.section-title {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.expand-section-title {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between title and icon */
  align-items: center; /* Center items vertically */
  cursor: pointer; /* Pointer cursor for clickable title */
  font-size: 1.25rem; /* Font size for the title */
}

.expand-section p, ul, li {
    font-size: 0.9rem;
}

.expand-section .container {
  padding: 0rem;
}

.extra-section-title {
    padding: 1.0rem 0.5rem;
    color: rgba(250, 250, 250, 0.4);
}

/* Remove the underline styles */
.underline {
  display: none; /* Or remove this class entirely */
}

/* ==========================================================================
   Callout Block
   ========================================================================== */
.callout-block {
  margin: 2rem 0;
  padding: 1.5rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 1.125rem;
  line-height: 1.6;
  background-color: rgba(255, 255, 255, 0.05);
}

@media (min-width: 56rem) {
  .callout-block {
    padding: 2rem;
    font-size: 1.25rem;
  }
}

/* ==========================================================================
   investments Styles
   ========================================================================== */
.investments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 0.5rem 0;
  max-width: 48rem;
}

.investments-grid-item {
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(250, 250, 250, 0.1);
}

.investments-school {
  font-size: 1.0rem;
  font-weight: bold;
  color: #fff;
}

.investments-name {
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.investments-title {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.4;
  margin: 0rem;
}

/* ==========================================================================
   Portfolio Styles
   ========================================================================== */
.portfolio-grid {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */ /* two columns */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive columns */
  /* gap: 1rem; */
  margin: 0.5rem 0;
  max-width: 48rem;
}

.portfolio-item {
  display: flex;
  align-items: center;
  padding: 0rem;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

.portfolio-content {
  flex-grow: 1;
}

.portfolio-school {
  font-size: 1.0rem;
  font-weight: bold;
  color: #fff;
}

.portfolio-name {
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.portfolio-title {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.4;
  margin: 0rem;
}

/* ==========================================================================
   Nested List Styles
   ========================================================================== */
ul {
  margin-left: 1.0rem;
  padding: 1.0rem 0rem;
}

/* New styles for the built by list */
.built-by-container {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)); /* Responsive columns */
}

.built-by-item {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  border-radius: 4px; /* Optional: add rounded corners */
  height: 55px; /* Adjust height as needed */
  margin-bottom: 0.75rem; /* Space between items */
  margin-right: 0.5rem; /* Space between items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
  border: 2px solid rgba(250, 250, 250, 0.1); /* Border styling */
}

.portfolio-grid-item {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    border-radius: 4px; /* Optional: add rounded corners */
    margin-bottom: 0.75rem; /* Space between items */
    margin-right: 0.5rem; /* Space between items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
    border: 1px solid rgba(250, 250, 250, 0.1); /* Border styling */
  }

.image-container {
  background-color: rgba(250,250,250, 0.9); 
  padding: 0.0rem 0.5rem; /* Padding around the image */
  flex: 0 0 auto; /* Prevent it from shrinking */
  height: 100%; 
  border-radius: 4px 0 0 4px; /* Optional: rounded corners on the left side */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.text-container {
  padding: 0.5rem;
  flex-grow: 1; /* Allow text container to take remaining space */
}

.company-logo {
  width: 4.0rem; /* Set logo width */
  height: auto; /* Maintain aspect ratio */
}

.company-name {
  font-weight: bold; /* Make company name bold */
}

.intro-text, .intro-list {
  font-size: 1.0rem; /* Adjust this value to match your content section paragraphs */
  line-height: 1.5; /* Optional: adjust line height for better readability */
}

.intro-list {
  margin: 0; /* Remove default margin */
  padding: 0rem 0.75rem; /* Add padding for indentation */
}

.intro-list li {
    margin: 0; /* Remove default margin */
    padding-left: 0rem; /* Add padding for indentation */
  }

@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

.expand-icon {
  font-size: 1.25rem; /* Adjust size as needed */
  margin-right: 0.5rem; /* Space between title and icon */
}

.label {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem;
  border: 2px solid rgba(250, 250, 250, 0.1);
  border-radius: 4px;
  color: white;
  white-space: nowrap;
  position: relative;
  width: auto;
  min-width: fit-content;
  box-sizing: border-box;
}

.logo-loop {
  width: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.logo-track {
  display: flex;
  animation: scroll var(--animation-duration) linear infinite;
  padding: 0.25rem;
  width: fit-content;
}

/* Regular scroll animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 2));
  }
}

/* Reverse scroll animation */
@keyframes scroll-reverse {
  0% {
    transform: translateX(calc(-100% / 2));
  }
  100% {
    transform: translateX(0);
  }
}

/* Apply reverse animation to reversed tracks */
.logo-track[style*="row-reverse"] {
  animation-name: scroll-reverse;
}

.last-item {
  margin-bottom: 2.0rem; /* Extra margin for the last item */
}

.counter {
  font-weight: bold;
  font-size: 1.5rem;
  /* transition: all 550.0s ease-out; */
}

@keyframes highlight {
  0% {
    color: #000;
  }
  50% {
    color: #007bff;
  }
  100% {
    color: #000;
  }
}

.label-link {
  text-decoration: none;
  color: inherit;
}

.school-logo {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* Header styles */
.nav-container {
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 56rem;
  margin: 0 auto;
}

/* Hamburger button */
.hamburger {
  order: 2;
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  position: relative;
  margin-left: 1rem;
}

.hamburger span {
  display: block;
  width: 2.0rem;
  height: 2px;
  background-color: white;
  margin: 0.4rem 0;
  transition: all 0.3s ease;
}

/* Mobile menu */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 32rem;
  background-color: #000;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 998;
}

.mobile-menu.open {
  transform: translateY(0);
}

.menu-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.menu-links a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Hamburger animation */
.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

/* Media queries */
@media (max-width: 56rem) {
  .hamburger {
    display: block;
  }

  .mobile-menu {
    display: block;
  }

  /* Hide desktop menu */
  .desktop-menu {
    display: none;
  }
}

/* Hide desktop menu on mobile */
.desktop-menu {
  display: none;
}

/* Hide mobile menu and hamburger on desktop */
@media (min-width: 56rem) {
  .hamburger,
  .mobile-menu {
    display: none;
  }
  
  .desktop-menu {
    display: block;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
}

/* Desktop menu styles */

.desktop-menu {
  display: none;  /* Hidden by default on mobile */
  margin-left: auto; /* This pushes the menu to the right */
}

.desktop-menu .menu-links {
  display: flex;
  gap: 2rem;
  flex-direction: row;
  justify-content: flex-end; /* Aligns items to the right */
}

.desktop-menu .menu-links a {
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 0.5rem 0;
  /* Remove any inherited mobile styles */
  background: none;
  border: none;
  width: auto;
  margin: 0;
  font-size: 1.0rem;
}

/* Keep existing mobile menu styles separate */
.mobile-menu .menu-links {
  /* Your existing mobile menu styles remain unchanged */
}

@media (min-width: 56rem) {
  .hamburger,
  .mobile-menu {
    display: none;
  }
  
  .desktop-menu {
    display: block;
  }
  
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
}

.section-spacer {
  margin-bottom: 1.5rem;
}

.people-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22.0rem, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
  max-width: 48rem;
}

.people-container .text-container {
  padding: 0.5rem 0.25rem;
}

.people-container span {
  font-size: 0.8rem;
  color: rgba(250, 250, 250, 0.8);
}

.people-image {
  width: 80px;
  height: 80px;
  border-radius: 20%;
  margin: 0.25rem;
  object-fit: cover;
}

.people-box {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  border-radius: 4px; /* Optional: add rounded corners */
  margin-bottom: 0.75rem; /* Space between items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */

  min-width: 21.0rem;
  max-width: 23.0rem;
  margin-right: 1.0rem;
}

.people-name {
  font-size: 1.0rem;
  font-weight: 700;
}

.people-title {
  font-size: 0.8rem;
  font-weight: 700;
}

.people-text {
  font-size: 0.8rem;
  color: rgba(250, 250, 250, 0.8);
}

.grey-text {
  color: rgba(250, 250, 250, 0.8);
}

/* Add new media query for very small screens */
@media (max-width: 380px) {
  * {
    font-size: 16px;
  }
  
  .people-container {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }
  
}

/* Desktop styles */
@media (min-width: 56rem) {
  button {
    width: auto; /* Change to auto width on desktop */
    min-width: 200px; /* Optional: set a minimum width */
    max-width: 300px; /* Optional: set a maximum width */
  }
}

/* Desktop styles - specifically for footer button */
@media (min-width: 56rem) {
  .footer button {  /* Target only buttons within footer */
    min-width: 200px;
    max-width: 300px;
  }
}

.responsive-text {
  text-align: left; /* Default to left alignment for desktop */
}

@media (max-width: 56rem) { /* Adjust the max-width as needed */
  .responsive-text {
    text-align: center; /* Center alignment for mobile */
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: black;
  padding: 2rem;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.modal-buttons button {
  width: auto;
  min-width: 120px;
}

.form-section {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.5rem;
  margin: 1rem 0;
}

.add-founder-btn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  width: auto;
  margin: 1rem 0;
}

.remove-founder-btn {
  background-color: transparent;
  color: rgba(255, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.8);
  width: auto;
  margin-top: 1rem;
}


/* ==========================================================================
   Intro Page
   ========================================================================== */

.mini-text {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: -0.5rem;
  margin-left: 1.0rem;
  font-style: italic;
}

/* ==========================================================================
   Scout Page
   ========================================================================== */

.scout-form {
  max-width: 48rem;
  margin-top: 4rem;
}

/* ==========================================================================
   Application Cycle Badges
   ========================================================================== */

.cycle-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: #e5edff;
  color: #3b82f6;
}

.cycle-unknown {
  background-color: #fee2e2;
  color: #ef4444;
}

/* ==========================================================================
   Form Styles
   ========================================================================== */
.application-form {
  /* max-width: 48rem; */
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.0rem;
}

.form-group-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem;
  margin: -0.5rem -0.5rem 1.0rem -0.5rem;
  border-radius: 4px 4px 0 0;
}

.form-subtitle {
  font-size: 1.2rem;
  font-weight: 700;
}

.form-input {
  width: 100%;
  padding: 0.5rem 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: white;
  transition: all 0.2s ease;
  outline: none;
}

.form-input:focus {
  border-bottom-width: 2px;
  border-color: white;
}

.form-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-input.error {
  border-color: rgba(255, 0, 0, 0.5);
}

.char-counter {
  display: block;
  text-align: right;
  font-size: 0.6rem;
  margin-bottom: 1.0rem;
  color:  rgba(255, 255, 255, 0.2);
  margin-top: 0.2rem;
  font-style: italic;
}

.char-counter.near-limit {
  color: #f0ad4e;
}

.char-counter.at-limit {
  color: #d9534f;
}

.error-message {
  color: rgba(255, 0, 0, 0.8);
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
}

textarea.form-input {
  min-height: 100px;
  resize: vertical;
}

/* Form section styles */
.founder-section {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
  background-color: rgba(255, 255, 255, 0.02);
}

/* Form buttons */
.add-founder-btn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
  width: auto;
  margin: 1rem 0;
}

.add-founder-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.remove-founder-btn {
  background-color: transparent;
  color: rgba(255, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.8);
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
  width: auto;
  margin-top: 1rem;
}

.remove-founder-btn:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

/* Form labels */
.form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
}

/* Form validation */
.form-input:not(:placeholder-shown):invalid {
  border-color: rgba(255, 0, 0, 0.5);
}

/* Form sections */
.application-info {
  margin-bottom: 4rem;
}

/* Submit button */
.submit-button {
  background-color: white;
  color: black;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 2rem;
}

@media (min-width: 56rem) {
  .submit-button {
    width: auto;
    min-width: 200px;
  }
}

/* Form section dividers */
.form-divider {
  margin: 2rem 0;
  border: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

/* Form section titles */
.form-section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.0rem;
  color: white;
}

.page-title {
  font-size: 2.0rem;
  font-weight: 700;
  margin-bottom: 2.0rem;
  color: white;
}

/* Required field indicator */
.required::after {
  content: '*';
  color: rgba(255, 0, 0, 0.8);
  margin-left: 4px;
}

/* Founder navigation styles */
.founder-nav {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.founder-nav-btn {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  width: auto;
  margin: 0;
}

.founder-nav-btn.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-color: white;
}

.founder-nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.post-apply-container {
  max-width: 50rem;
  margin-bottom: 4rem;
}

.faq-container {
  margin-bottom: 4rem;
}

.faq-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.0rem;
  color: white;
}

/* Footer message styles */
.footer-message {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.footer-message.success {
  color: #4CAF50;
}

.footer-message.error {
  color: #f44336;
}

/* Disabled button state */
.footer-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* ==========================================================================
   Loading Modal
   ========================================================================== */
.loading-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  position: relative;
  z-index: 1001;
}

.loading-modal-content p {
  color: white;
  font-size: 1.2rem;
  margin: 0;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Mobile styles */
@media (max-width: 56rem) {
  .loading-modal-content {
    width: 90%;
  }
  
  .loading-modal-content p {
    font-size: 1rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0rem;
  margin: 0 auto;
}

.link-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0.5rem;
}

.link-column h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.link-column a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 1.0rem;
  transition: color 0.2s ease;
}

.link-column a:hover {
  color: #fff;
}

@media (max-width: 640px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 0rem;
  }

  /* First column (social links) spans full width */
  .footer-links .link-column:first-child {
    grid-column: 1 / -1;
  }

  /* Second and third columns take 50% width each */
  .footer-links .link-column:nth-child(2),
  .footer-links .link-column:nth-child(3) {
    grid-column: span 1;
  }
}

.social-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.social-link {
  display: inline-block;
}

.social-icon {
  width: 44px;
  height: 44px;
}

.error-submit-container {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 4px;
  background-color: rgba(255, 0, 0, 0.1);
}

.error-title {
  color: rgba(255, 0, 0, 0.8);
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.error-time {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.try-again-btn {
  background-color: transparent;
  color: rgba(255, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.8);
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.try-again-btn:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.year-section {
  margin-bottom: 40px;
}

.year-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  margin-left: 0.5rem;
  color: #333;
}

.empty-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  width: 100%;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.role-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.role-btn {
  padding: 8px;
  margin: 0;
  font-size: 1.0rem;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;
}

.role-btn.selected {
  background-color: white;
  border-color: white;
  font-weight: 600;
  color: #333;
}

.other-role-input {
  margin-top: 10px;
}

.role-reset-btn {
  margin-left: 12px;
  opacity: 0.7;
  font-size: 1.0rem;
  padding: 8px;
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  cursor: pointer;
}

.role-reset-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.no-bullet {
  list-style: none;
  padding-left: 0;
}

.no-bullet::before {
  content: none;
}


